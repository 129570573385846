const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // adminBasename: 'admin',
    basename: '',
    defaultPath: '/',
    productionBaseName: '',
    // productionBaseName: 'radguardian',
    remoteDevServer: 'http://localhost:3000',
    appVersion: 'v1.28',
    fontFamily: `'Inter', sans-serif`,
    borderRadiusXs: '.25rem',
    borderRadiusSm: '.5rem',
    borderRadiusMd: '.75rem',
    borderRadiusLg: '1rem',
    borderRadiusXl: '2.5rem',
    author: 'Qalib SRL',
    keywords: 'Rapporto, PDF, Qalib'
    // navbarHeight: 64,
};

export default config;
