import { lazy } from 'react';

// project imports
import AuthLayout from 'layout/AuthLayout';
import ContainerLayout from 'layout/ContainerLayout';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/lazy/LoadableView';

// const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
// const AuthLayout = Loadable(lazy(() => import('layout/AuthLayout')));
// const ContainerLayout = Loadable(lazy(() => import('layout/ContainerLayout')));

// ////////////* ADMIN ROUTES *////////////
// * Dashboard
const Settings = Loadable(lazy(() => import('../views/backend/settings')));
const Profile = Loadable(lazy(() => import('../views/backend/settings/profile')));

const AdminDashboard = Loadable(lazy(() => import('../views/backend/dashboard')));

// ! ANAGRAFICHE
// * Clienti
const Clients = Loadable(lazy(() => import('../views/backend/client')));
const ClientEdit = Loadable(lazy(() => import('../views/backend/client/edit')));
// * Sedi
const SedeEdit = Loadable(lazy(() => import('../views/backend/client/sede')));
// * Reparti
const RepartoEdit = Loadable(lazy(() => import('../views/backend/client/reparto')));
// * Ambienti
const AmbienteEdit = Loadable(lazy(() => import('../views/backend/client/ambiente')));
// * Lavoratori
const LavoratoreEdit = Loadable(lazy(() => import('../views/backend/client/lavoratore')));
// * Utenti cliente
const UtenteCliEdit = Loadable(lazy(() => import('../views/backend/client/utentecli')));
// !

// ! GESTIONE MAGAZZINI
// * Magazzini
const Warehouses = Loadable(lazy(() => import('../views/backend/warehouse')));
const WarehouseInstaEdit = Loadable(lazy(() => import('../views/backend/warehouse/edit/insta')));
const WarehouseOSLEdit = Loadable(lazy(() => import('../views/backend/warehouse/edit/osl')));
const WarehouseEZCaseEdit = Loadable(lazy(() => import('../views/backend/warehouse/edit/ezcase')));
// !

// * Dosimetri
const Dosimeters = Loadable(lazy(() => import('../views/backend/meters')));
const MeterInstaEdit = Loadable(lazy(() => import('../views/backend/meters/edit/insta')));
// * Processi
const Processes = Loadable(lazy(() => import('../views/backend/processes')));
const LettureRaw = Loadable(lazy(() => import('../views/backend/processes/raw')));
const AssegnazioniInsta = Loadable(lazy(() => import('../views/backend/client/assegnazioni')));
const LettureInsta = Loadable(lazy(() => import('../views/backend/client/letture')));
const RDPInsta = Loadable(lazy(() => import('../views/backend/client/rdpinsta')));
const RDPInstaAnno = Loadable(lazy(() => import('../views/backend/client/rdpinstaanno')));
// * Gestione
const Utils = Loadable(lazy(() => import('../views/backend/management')));
const ClassEdit = Loadable(lazy(() => import('../views/backend/management/classes')));
const ColorEdit = Loadable(lazy(() => import('../views/backend/management/colors')));
const StateEdit = Loadable(lazy(() => import('../views/backend/management/states')));
const WorkingStateEdit = Loadable(lazy(() => import('../views/backend/management/workingstates')));
const ContactTypeEdit = Loadable(lazy(() => import('../views/backend/management/contacttypes')));
const PositionEdit = Loadable(lazy(() => import('../views/backend/management/positions')));
const RadiationEdit = Loadable(lazy(() => import('../views/backend/management/radiations')));
const ModelEdit = Loadable(lazy(() => import('../views/backend/management/models')));

// ////////////* WORKER ROUTES *////////////
const HomeClientPage = Loadable(lazy(() => import('../views/backend/worker-level')));
const BindingPage = Loadable(lazy(() => import('../views/backend/worker-level/assegnazioni')));
const AssegnazioniInstaWorker = Loadable(lazy(() => import('../views/backend/worker-level/assegnazioni-insta')));

// const FreqEdit = Loadable(lazy(() => import('../views/backend/management/freq')));
// const UserEdit = Loadable(lazy(() => import('../views/backend/management/user')));
// const RoleEdit = Loadable(lazy(() => import('../views/backend/management/role')));

// ! 404
const NotFound = Loadable(lazy(() => import('../views/not-found')));
// * Auth
const Login = Loadable(lazy(() => import('../views/auth/authentication3/Login3')));

// ==============================|| ROUTING ||============================== //

const AuthRoutes = {
    path: '/',
    element: <ContainerLayout />,
    children: [
        // ? AUTH
        {
            path: '/',
            element: <AuthLayout />,
            children: [
                {
                    path: '/login',
                    element: <Login />
                    // element: <ArticleList />
                }
            ]
        },
        // // * NOT FOUND
        {
            path: '*',
            element: <MainLayout />,
            children: [
                {
                    path: '*',
                    element: <NotFound />
                }
            ]
        }
    ]
};
const WorkerRoutes = {
    path: '/',
    element: <ContainerLayout />,
    children: [
        // ? WORKER
        {
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: '',
                    element: <HomeClientPage />
                },
                {
                    path: 'settings',
                    element: <Settings />
                },
                {
                    path: 'profile',
                    element: <Profile />
                },
                {
                    path: 'assegnazioni',
                    element: <BindingPage />
                },
                {
                    path: 'assegnazioni-insta/:processId',
                    element: <AssegnazioniInstaWorker />
                }
            ]
        }
    ]
};
const AdminRoutes = {
    path: '/',
    element: <ContainerLayout />,
    children: [
        // ? ADMIN
        {
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: '',
                    element: <AdminDashboard />
                },
                {
                    path: 'settings',
                    element: <Settings />
                },
                {
                    path: 'profile',
                    element: <Profile />
                },

                {
                    path: 'dashboard',
                    element: <AdminDashboard />
                },
                {
                    path: 'clienti',
                    element: <Clients />
                },
                {
                    title: 'Clienti',
                    path: 'clienti/:clientId',
                    element: <ClientEdit />
                },
                {
                    path: 'clienti/:clientId/sedi/:sedeId',
                    element: <SedeEdit />
                },
                {
                    path: 'clienti/:clientId/utenti/:userId',
                    element: <UtenteCliEdit />
                },
                {
                    path: 'clienti/:clientId/sedi/:sedeId/reparti/:repartoId',
                    element: <RepartoEdit />
                },
                {
                    path: 'clienti/:clientId/sedi/:sedeId/reparti/:repartoId/ambienti/:ambienteId',
                    element: <AmbienteEdit />
                },
                {
                    path: 'clienti/:clientId/sedi/:sedeId/reparti/:repartoId/lavoratori/:lavoratoreId',
                    element: <LavoratoreEdit />
                },
                {
                    path: 'magazzini',
                    element: <Warehouses />
                },
                {
                    path: 'magazzini/insta/:warehouseId',
                    element: <WarehouseInstaEdit />
                },
                {
                    path: 'magazzini/OSL/:warehouseId',
                    element: <WarehouseOSLEdit />
                },
                {
                    path: 'magazzini/ezcase/:warehouseId',
                    element: <WarehouseEZCaseEdit />
                },
                {
                    path: 'dosimetri',
                    element: <Dosimeters />
                },
                {
                    path: 'dosimetri/insta/:meterId',
                    element: <MeterInstaEdit />
                },
                // {
                //     path: 'dosimetri/osl/:meterId',
                //     element: <MeterOSLEdit />
                // },
                {
                    path: 'processi',
                    element: <Processes />
                },
                {
                    path: 'processi/raw/:processId',
                    element: <LettureRaw />
                },
                {
                    path: 'clienti/:clientId/assegnazioni-insta/:processId',
                    element: <AssegnazioniInsta />
                },
                {
                    path: 'clienti/:clientId/sedi/:sedeId/reparti/:repartoId/assegnazioni-insta/:processId',
                    element: <AssegnazioniInsta />
                },
                {
                    path: 'clienti/:clientId/letture-insta/:processId',
                    element: <LettureInsta />
                },
                {
                    path: 'clienti/:clientId/rdp-insta/:processId',
                    element: <RDPInsta />
                },
                {
                    path: 'clienti/:clientId/rdp-insta-anno/:processId',
                    element: <RDPInstaAnno />
                },

                {
                    path: 'gestione',
                    element: <Utils />
                },
                {
                    path: 'gestione/classificazioni/:uid',
                    element: <ClassEdit />
                },
                {
                    path: 'gestione/colori/:uid',
                    element: <ColorEdit />
                },
                {
                    path: 'gestione/posizioni/:uid',
                    element: <PositionEdit />
                },
                {
                    path: 'gestione/radiazioni/:uid',
                    element: <RadiationEdit />
                },
                {
                    path: 'gestione/modelli/:uid',
                    element: <ModelEdit />
                },
                {
                    path: 'gestione/stati/:uid',
                    element: <StateEdit />
                },
                {
                    path: 'gestione/statifunz/:uid',
                    element: <WorkingStateEdit />
                },
                {
                    path: 'gestione/tipicontatto/:uid',
                    element: <ContactTypeEdit />
                }
            ]
        }
    ]
};

export { AdminRoutes, AuthRoutes, WorkerRoutes };
